<template>
    <div>
        <!-- Render TIFF image -->
        <canvas v-if="isTiff" ref="canvas"></canvas>

        <!-- Render other images normally -->
        <silent-box v-else :gallery="gallery"></silent-box>
    </div>
</template>

<script>
import Tiff from 'tiff.js/tiff';

export default {
    props: {
        src_input: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            isTiff: false,
            gallery: []
        };
    },
    mounted() {
        if (this.src_input.endsWith('.tif') || this.src_input.endsWith('.tiff')) {
            this.isTiff = true;
            this.loadTiffImage();
        } else {
            this.gallery = [{ src: this.src_input, description: this.src_input, thumbnailWidth: '220px' }];
        }
    },
    methods: {
        loadTiffImage() {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.src_input);
            xhr.responseType = 'arraybuffer';
            xhr.onload = (e) => {
                const buffer = xhr.response;
                const tiff = new Tiff({ buffer: buffer });
                const canvas = this.$refs.canvas;
                canvas.width = tiff.width();
                canvas.height = tiff.height();
                const ctx = canvas.getContext('2d');
                ctx.drawImage(tiff.toCanvas(), 0, 0);
            };
            xhr.send();
        }
    }
};
</script>