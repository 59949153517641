<template>
<div>
    <form @submit.prevent="submit">
        <div class="alert alert-success" v-show="success">{{successMessage}}</div>
        <div class="alert alert-danger" v-show="error"><pre>{{errorMessage}}</pre></div>
    
 <div class="row mb-3">
                            <label for="name" class="col-md-4 col-form-label text-md-right">Ime</label>

                            <div class="col-md-6">
                                <input id="name" type="text" v-model="data.name" class="form-control " name="name" value="" required autocomplete="name" autofocus>

                             
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="email" class="col-md-4 col-form-label text-md-right">Imejl adresa</label>

                            <div class="col-md-6">
                                <input id="email" @blur="handleBlur" type="email" v-model="data.email" class="form-control " name="email" value="" required autocomplete="email">

                             
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="password" class="col-md-4 col-form-label text-md-right">Šifra</label>

                            <div class="col-md-6">
                                <input id="password" type="password" v-model="data.password" class="form-control " name="password" required autocomplete="new-password">

                               
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Potvrdite šifru</label>

                            <div class="col-md-6">
                                <input id="password-confirm" type="password" v-model="data.password_confirmation" class="form-control" name="password_confirmation" required autocomplete="new-password">
                            </div>
                        </div>

                        <div class="row mb-0">
                            <div class="col-md-6 offset-md-4">
                                <button :disabled="disableSubmit" type="submit" class="btn btn-primary">
                                   Potvrdi
                                </button>
                            </div>
                        </div>                

    </form>
    </div>
</template>

<script>
    import Datepicker from './datepicker.vue';
    export default {
    
        data(){
            return {
                data : {name: "",
                email: "",
                password: "",
                password_confirmation: ""},
                success: false,
                error : false,
                errorMessage: "",
                edit: false,
                successMessage: "",
                disableSubmit : false
            }
        },
        methods : {
            submit(){
                this.success = false;
                this.error = false;
                
                let correct = true;
                let emptyFields = "";
                if(this.data.name == ""){
                    correct = false;
                    emptyFields += "Ime\n"
                }
                if(this.data.email == ""){
                    correct = false;
                    emptyFields += "Imejl\n"
                }
                if(this.data.password == ""){
                    correct = false;
                    emptyFields += "Šifra\n"
                }
                

                if(!correct){
                    this.errorMessage = "Sledeća polja su obavezna: \n\n" + emptyFields;
                    this.error = true;
                    return;
                }

                if(this.data.password != this.data.password_confirmation){
                    this.errorMessage = "Unete šifre se ne podudaraju!\n";
                    this.error = true;
                    return;

                }

              
                //Kreiramo novog administratora
                axios.post("/admin", this.data).then(response => {
                    this.data = {};
                    this.success = true;
                    this.successMessage = "Uspešno ste kreirali novog administratora!";
                    
                }).catch(error => {
                   
                        this.errorMessage = "Došlo je do greške prilikom kreiranja novog administratora! Molimo Vas da pokušate ponovo.";
                    
                    
                    this.error = true;
                });

                
            },
             handleBlur(e) {
                 axios.post("/admin/user", {email: this.data.email}).then(response => {
                    this.disableSubmit = false;
                    
                }).catch(error => {
                    if(error.response.status == 409){
                        this.errorMessage = "Korisnik sa zadatom imejl adresom već postoji!";
                        this.error = true;
                        this.disableSubmit = true;
                    }
                    
                    
                });
                }

            }


    }
</script>

