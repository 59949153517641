<template>
  <div>
    

    <b-form-datepicker
      
      v-model="localValue"
      v-bind="labels || {}"
      :locale="locale"
      :start-weekday="weekday"
      :show-decade-nav="showDecadeNav"
      :hide-header="hideHeader"
      class="mb-2"
      calendar-width="100%"
      :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }"
     ></b-form-datepicker>
     
   </div>
</template>

<script>
  export default {
     props: {
            value: {
                type: String,
                required: true
            }
        },
    data() {
      return {
       
        locale: 'en-GB',
        showDecadeNav: false,
        hideHeader: false,
        weekday: 1,
        weekdays: [
          { value: 0, text: 'Nedelja' },
          { value: 1, text: 'Ponedeljak' },
          { value: 2, text: 'Utorak' },
          { value: 3, text: 'Sreda' },
          { value: 4, text: 'Četvrtak' },
          { value: 5, text: 'Petak' },
          { value: 6, text: 'Subota' }
        ],
        labels: {
            labelPrevDecade: 'Prethodna decenija',
            labelPrevYear: 'Prethodna godina',
            labelPrevMonth: 'Prethodni mesec',
            labelCurrentMonth: 'Trenutni mesec',
            labelNextMonth: 'Naredno mesec',
            labelNextYear: 'Naredna godina',
            labelNextDecade: 'Naredna decenija',
            labelToday: 'Danas',
            labelSelected: '',
            labelNoDateSelected: 'Nije selektovan datum',
            labelCalendar: 'Kalendar',
            labelNav: 'Kalendernavigation',
            labelHelp: 'Koristite kursor ili strelice za navigaciju'
         
        }
      }
    },        computed: {
            localValue: {
                get() { return this.value },
                set(localValue) {this.$emit('input', localValue)}
            }
    }
  }
</script>