<template><div>
  <div class="row">
    <div class="col-md-12">
   <div class="alert alert-danger" v-show="limit">Maksimalna veličina fajla je {{max_size}}MB!</div>
   
   <input  class="form-control" ref="fileupload" type="file" v-bind:accept="format" @change="onChange($event)">


  </div>
  </div>

    <div class="row mt-3" v-if="fileExists">
    
      
          <div class="col-md-12">
      Postojeća datoteka: <a target="#" v-bind:href="'/storage/data/'+this.fileName">{{ this.fileName }}</a> 
        </div>

        <b-link @click="deleteFile">Obriši postojeću datoteku</b-link>

       
  </div>
</div>

</template>

<script>
  export default { 
      props: {
            format: {
                type: String,
                required: true
            },
            value: { 
                type: [File, String],
                required: false
            },
            max_size: { 
                type: Number,
                required: true
            },
            resource: { 
                type: String,
                required: false
            },
            resource_id: { 
                type: Number,
                required: false
            },
            field_name: { 
                type: String,
                required: false
            },
        },
    data() {
      return {
        u_file: null,
        limit: false,
        fileExists : false,
        fileName : ""
      }
    } ,  computed: {
            localValue: {
                get() { return this.value },
                set(localValue) { this.$emit('input', localValue)}
            }
    },
    mounted(){
      if(this.value != null) {
        this.fileExists = true;
      }
    },
    methods: {
        onChange(event) {
            
            if(event.target.files[0].size <= (this.max_size * 1024 * 1024)){
                this.limit = false;
                this.localValue = event.target.files[0];
            }
            else{
                event.target.value=null;
                this.limit = true;
            }
            
        },
        deleteFile(){
           this.$bvModal.msgBoxConfirm('Da li ste sigurni da želite daobrišete ovu datoteku?', {
          title: 'Upozorenje!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Potvrdi',
          cancelTitle: 'Odustani',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
                if(value){
                   
                  var data = Object();
                  data.resource = this.resource;
                  data.resource_id = this.resource_id;
                  data.field_name = this.field_name;

                  axios.post("/file/delete", data).then(response => {
                    
                  this.fileExists = false;
                    
                }).catch(error => { 

                    this.boxOne = ''
                    this.$bvModal.msgBoxOk("Došlo je do greške prilikom brisanja datoteke!")
                    .then(value => {
           

                         })
                    .catch(err => {
                        // An error occurred
                    })
                });



                }
          });

        }
    },
      watch: {
    value(val, oldVal) {
      if(val != "" && val != null && typeof val == "string"){
        
        this.fileExists = true;
        this.fileName = val;
        this.localValue = null;}
    }
  }
  }
</script>