<template>
  <div>
    <b-table :items="items" :fields="fields" responsive="sm">

      

            <template #cell(Akcije)="row">
     

        <b-button size="sm" v-bind:href="'/competition/'+ row.item.id +'/edit'" class="mr-2">
           Ažuriraj
        </b-button>

        <b-button size="sm" v-bind:href="'/admin/submitted/'+ row.item.id" class="mr-2">
           Izlistaj prijave
        </b-button>  
      </template>


    </b-table>

    <div class="row">
        <b-button size="sm" href="/competition/create" class="mr-2">
           Kreiranje novog konkursa
        </b-button>

    </div>
  </div>
</template>

<script>
  export default {
        props: {
            input_data: {
                type: Array,
                required: true
            }
        },
       data() {
      return {
        fields: [,{
            key : 'name',
            label: 'Naziv'
        }, {
            key : 'starts',
            label: 'Početak'
        }, {
            key : 'ends',
            label: 'Kraj'
        }, 'Akcije'],
        items: [
        ],

        success: false,
        error : false,
      }
    },
        mounted(){ 
            this.items = this.input_data;
     
        }
  }
</script>