/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */



require('./bootstrap');
import VueSilentbox from 'vue-silentbox';

window.Vue = require('vue').default;



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
import { BRow } from 'bootstrap-vue';
Vue.component('b-row', BRow)
import { BCol } from 'bootstrap-vue';
Vue.component('b-col', BCol)

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('datepicker', require('./components/datepicker.vue').default);
Vue.component('competition-form', require('./components/competitionForm.vue').default);
Vue.component('competition-list', require('./components/competitionList.vue').default);
Vue.component('admin-list', require('./components/adminList.vue').default);
Vue.component('admin-form', require('./components/adminForm.vue').default);
Vue.component('custom-textarea', require('./components/customTextarea.vue').default);
Vue.component('project-form', require('./components/projectForm.vue').default);
Vue.component('file-upload', require('./components/fileUpload.vue').default);
Vue.component('attachment-form', require('./components/attachmentForm.vue').default);
Vue.component('museum-form', require('./components/museumForm.vue').default);
Vue.component('expert-form', require('./components/expertForm.vue').default);
Vue.component('publication-form', require('./components/publicationForm.vue').default);
Vue.component('delete-link', require('./components/deleteLink.vue').default);
Vue.component('image-viewer', require('./components/imageViewer.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import { CalendarPlugin } from 'bootstrap-vue'
Vue.use(CalendarPlugin)

import { BTabs } from 'bootstrap-vue'
Vue.component('b-tabs', BTabs)
import { BTab } from 'bootstrap-vue'
Vue.component('b-tab', BTab)
import { BListGroup } from 'bootstrap-vue'
Vue.component('b-list-group', BListGroup)
import { BListGroupItem } from 'bootstrap-vue'
Vue.component('b-list-group-item', BListGroupItem)

import { BFormDatepicker } from 'bootstrap-vue';
Vue.component('b-form-datepicker', BFormDatepicker);

import { BTable } from 'bootstrap-vue'
Vue.component('b-table', BTable)

import { BButton } from 'bootstrap-vue'
Vue.component('b-button', BButton)

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

import { BFormFile } from 'bootstrap-vue'
Vue.component('b-form-file', BFormFile)

import { BFormInput } from 'bootstrap-vue'
Vue.component('b-form-input', BFormInput)

import { BAlert } from 'bootstrap-vue'
Vue.component('b-alert', BAlert)

import { BCard } from 'bootstrap-vue'
Vue.component('b-card', BCard)

import { BCardText } from 'bootstrap-vue'
Vue.component('b-card-text', BCardText)

import { BLink } from 'bootstrap-vue'
Vue.component('b-link', BLink)

Vue.use(VueSilentbox)

const app = new Vue({
    el: '#app',
});


