<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">

<div class="row mb-3">
                           

            <div class="col-md-12">
      <b-alert variant="warning" show>Polja označena zvezdicom (*) obavezno je popuniti. Ukoliko niste ispunili sva polja nećete biti u prilici da pošaljete Vašu prijavu.</b-alert>
  </div></div>
  <!-- Cover photo ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="cover_photo" class="col-md-4 col-form-label text-md-left">
                            * Naslovna fotografija <p>je fotografija kojom se ilustruje kandidatura na komunikacionim kanalima NK ICOM Srbija - do 3MB (72 dpi) (JPEG, JPG, TIFF)</p></label>
                                <file-upload :format="'.jpg, .jpeg, .tiff, .tif'" resource="publication" :resource_id="data.id" field_name="cover_photo" :max_size="3" v-model="data.cover_photo" id="cover_photo" ></file-upload>

                               
                            </div>
                         
            </div>
        <!-- Naziv ---------------------------------------------------------------->
       <div class="row ">
                           

                            <div class="col-md-12">
                            <label for="name" class="col-form-label text-md-right">* Pun naziv publikacije</label>
                                <input id="name" type="text" class="form-control" name="name" v-model="data.name" autofocus>

                               
                            </div>
                         
            </div>

        <!-- Autori ---------------------------------------------------------------->
       <div class="row ">
                           

                            <div class="col-md-12">
                            <label for="authors" class="col-form-label text-md-right">* Autor/i, urednik/ci</label>
                                <input id="authors" type="text" class="form-control" name="authors" v-model="data.authors" autofocus>

                               
                            </div>
                         
            </div>

        <!-- Izdavac ---------------------------------------------------------------->
       <div class="row ">
                           

                            <div class="col-md-12">
                            <label for="publisher" class="col-form-label text-md-right">* Izdavač </label>
                                <input id="publisher" type="text" class="form-control" name="publisher" v-model="data.publisher" autofocus>

                               
                            </div>
                         
            </div>

        <!-- Publikacija pdf ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="publication_pdf" class="col-md-4 col-form-label text-md-left">
                            * Web verzija preloma publikacije - do 20MB (PDF)</label>
                                <file-upload :format="'.pdf'" :max_size="20" resource="publication" :resource_id="data.id" field_name="publication_pdf" v-model="data.publication_pdf" id="publication_pdf" ></file-upload>

                               
                            </div>
                         
            </div>

       <!-- Description ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="description" class="col-md-4 col-form-label text-md-left">*Opis sadržaja, značaja i ciljeva publikacije
</label>
                                <custom-textarea v-model="data.description" id="description" :max_words="300"></custom-textarea>

                               
                            </div>
                         
            </div>

 
                   

        <!-- Recenzije ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="recension_1" class="col-md-4 col-form-label text-md-left">
                            * Recenzija 1 - do 3MB (PDF)</label>
                                <file-upload :format="'.pdf'" :max_size="3" resource="publication" :resource_id="data.id" field_name="recension_1" v-model="data.recension_1" id="recension_1" ></file-upload>

                               
                            </div>
                         
            </div>

            <div class="row mb-3">
                           

                            <div class="col-md-12">
                                <label for="recension_2" class="col-md-4 col-form-label text-md-left">
                            Recenzija 2 - do 3MB (PDF)</label>
                                <file-upload :format="'.pdf'" resource="publication" :resource_id="data.id" field_name="recension_2" :max_size="3" v-model="data.recension_2" id="recension_2" ></file-upload>

                               
                            </div>
                         
            </div>

            <div class="row mb-3">
                           

                            <div class="col-md-12">
                                <label for="recension_3" class="col-md-4 col-form-label text-md-left">
                             Recenzija 3 - do 3MB (PDF)</label>
                                <file-upload :format="'.pdf'" resource="publication" :resource_id="data.id" field_name="recension_3" :max_size="3" v-model="data.recension_3" id="recension_3" ></file-upload>

                               
                            </div>
                         
            </div>


        <!-- Aktivnosti ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="activity_1" class="col-md-4 col-form-label text-md-left">
                            Prilozi sa promotivnih aktivnosti i modela distribucije (promocije, online promocije, novinski članci, intervjui, društvene mreže...) - do 3MB (72 dpi) (JPEG, JPG, TIFF)
 </label>
                                <file-upload :format="'.jpg, .jpeg, .tiff, .tif'" resource="publication" :resource_id="data.id" field_name="activity_1" :max_size="3" v-model="data.activity_1" id="activity_1" ></file-upload>

                               
                            </div>
                         
            </div>

            <div class="row mb-3">
                           

                            <div class="col-md-12">
                                <file-upload :format="'.jpg, .jpeg, .tiff, .tif'" resource="publication" :resource_id="data.id" field_name="activity_2" :max_size="3" v-model="data.activity_2" id="activity_2" ></file-upload>

                               
                            </div>
                         
            </div>

            <div class="row mb-3">
                           

                            <div class="col-md-12">
                                <file-upload :format="'.jpg, .jpeg, .tiff, .tif'" resource="publication" :resource_id="data.id" field_name="activity_3" :max_size="3" v-model="data.activity_3" id="activity_3" ></file-upload>

                               
                            </div>
                         
            </div>


                  <!--  linkovi ---------------------------------------------------------------->
<label class="col-md-4 col-form-label text-md-left">
                            Linkovi</label>
          <div v-for="(item, index) in data.links" :key="index" class="row mb-3">
                           

                            <div class="col-md-10">
                            <b-form-input type="url" v-model="item['link']" placeholder="Link" ></b-form-input>

                            </div>

                            <div class="col-md-2">
                            <button type="button" v-on:click="removeLink(index)" class="btn btn-primary">Izbaci link</button>

                            </div>
                            </div>
<div  class="row mb-3">
<div class="col-md-12">
     
     <button type="button" v-on:click="addLink" class="btn btn-primary">Dodaj link</button>
    </div>
                </div>

                <div class="row mb-3">
                           

            <div class="col-md-12">
      <b-alert variant="warning" show>
          <p>PROVERITE DA LI STE POPUNILI SVA OBAVEZNA POLJA!!!
NEĆE BITI MOGUĆE POSLATI PRIJAVU UKOLIKO JE NEKO OD OBAVEZNIH POLJA NEPOPUNJENO.</p>
          <p>Nakon čuvanja prijave moći ćete da vršite izmene koliko god želite do momenta njenog slanja. 
Savetujemo, za svaki slučaj, da sve što ste uneli u formular (tekstove, fotografije, linkove) imate sačuvane i u svom personalnom računaru u jednom folderu. </p>
          <p>Nakon što odlučite da pošaljete prijavu i kliknete na dugme “<b>Pošalji</b>” pojaviće Vam se polje u kojem morate potvrditi slanje. Nakon potvrde Vaša prijava je zvanična i vidljiva administratoru sajta. </p>
           </b-alert>
  </div></div>

                             <div class="alert alert-success" v-show="success">{{successMessage}}</div>
        <div class="alert alert-danger" v-show="error"><pre>{{errorMessage}}</pre></div>

        

              <div class="row mb-3 mt-4">
  <div class="col-md-12">
  <button type="submit" v-on:click="save" class="btn btn-primary">Sačuvaj</button>
    <button type="submit" v-on:click="confirm" class="btn btn-primary">Pošalji prijavu</button>
  </div>
  </div>    

            </div>
        </div>
    </div>
</template>


<script>
import customTextarea from './customTextarea.vue'
export default {
                props: {
            input_data: {
                type: Object,
                required: false
            }
        }, components: { customTextarea },
         data(){
            return {
                data : {name: "",
                    authors : "",
                    publisher : "",
                    publication_pdf : null,
                    description : "",
                    cover_photo: null,
                    recension_1 : null,
                    recension_2 : null,
                    recension_3 : null,
                    activity_1 : null,
                    activity_2 : null,
                    activity_3 : null,
                    links : [ ]
                },
                success: false,
                error : false,
                errorMessage: "",
                edit: false,
                successMessage: "",
                preventSuccess: false
            }
         },   
         mounted() {
              if(this.input_data != null){

                     this.data = JSON.parse(JSON.stringify(this.input_data, this.replaceNull));
                    
                     this.edit = true;   
                }
        },
        methods : {

            addLink(){
                this.data.links.push({});
            },

            removeLink(index){
                this.$delete(this.data.links, index)
            },

            save(){
                this.submit(false);
            },


            submit(confirmation){
               if(this.edit){
                  return this.update(confirmation);
               }
               else{
                 return  this.insert(confirmation);
               }
                
             },
             confirm(){

          this.$bvModal.msgBoxConfirm('Jednom poslata prijava se ne može više menjati. Da li ste sigurni da želite da pošaljete ovu prijavu?', {
          title: 'Upozorenje!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Potvrdi',
          cancelTitle: 'Odustani',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
                if(value){
                    this.submit(true);

                }
          })

             },
            async sendConfirm(id){

                

                 axios.put("/publication/"+id).then(response => {
                    
                    this.success = true;
                    this.successMessage = "Uspešno ste poslali prijavu!";
                    
                }).catch(error => { 
                     var message = "";
                    if(error.response.status == 409){
                        message = 'Niste ispravno popunili sva obavezna polja! Molimo Vas da proverite svoju prijavu.';
                    }
                    else{
                        message = 'Neuspešno slanje prijave! Molimo Vas da pokušate ponovo.';
                    }
                    this.boxOne = ''
                    this.$bvModal.msgBoxOk(message)
                    .then(value => {
                     this.boxOne = value;
                     window.location.href = '/publication/'+id+"/edit";

                         })
                    .catch(err => {
                        // An error occurred
                    })
              

                });

                 

             },
            async insert(confirmation){
                this.error = false;
                var formdata = this.createFormDataMain();
                
                return    axios.post("/publication", formdata,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(response => {

                       if(!confirmation){
                                                 this.boxOne = ''
                                this.$bvModal.msgBoxOk('Uspešno čuvanje prijave!')
                                .then(value => {
                                this.boxOne = value;
                                window.location.href = '/publication/'+response.data.id+"/edit";

                                    })
                                .catch(err => {
                                    // An error occurred
                                })
                                }
                                else{
                                    this.sendConfirm(response.data.id);
                                }
                 
         
                                
                }).catch(error => {
                     this.errorMessage = "Dodavanje prijave nije uspešno! Molimo Vas da pokušate ponovo.";
                    this.error = true;
                    
                    
                });    

            },
            async update(confirmation){
                
                  this.error = false;
                var formdata = this.createFormDataMain();
                
                return    axios.post("/publication/"+this.input_data.id + "/update", formdata,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(response => {
                    if(!confirmation){
                                this.successMessage = "Uspešno čuvanje prijave!"
                                this.success = true;
                                }
                                else{
                                    this.sendConfirm(this.input_data.id);
                                }
                       

  }).catch(error => {
                     this.errorMessage = "Ažuriranje prijave nije uspešno! Molimo Vas da pokušate ponovo.";
                    this.error = true;
                    
                    
                });    

            },

            replaceNull(key, value){
                if(key == 'cover_photo' || key == 'recommendation' || key == 'image')
                    return value;
                else
                    return (value === null) ? "" : value;
            },
            
             createFormDataMain(){
                 
                 var formdata = new FormData();

                 for (var key in this.data) {
                    if (this.data.hasOwnProperty(key)) {
                        if(key == "links"){
                            formdata.append(key, JSON.stringify(this.data[key]));
                            continue;
                            }
                        if(this.data[key] != null)
                            formdata.append(key, this.data[key]);
                    }
                 }

                 return formdata;
             }
             
        }
}
</script>