<template>
  <div>
        <div class="alert alert-success" dismissible v-show="success">Uspešno ste izbrisali administratora!</div>
        <div class="alert alert-danger" dismissible v-show="error"><pre>Došlo je do greške prilikom brisanja administratora! Molimo Vas da pokušate ponovo.</pre></div>
    <b-table :items="items" :fields="fields" responsive="sm">

      

            <template #cell(Akcije)="row">
        <b-button size="sm" @click="deleteMe(row.item.id, row.item.name)" class="mr-2">
           Obriši
        </b-button>

      
      
      </template>


    </b-table>

    <div class="row">
        <b-button size="sm" href="/admin/create" class="mr-2">
           Kreiranje novog administratora
        </b-button>

    </div>
  </div>
</template>

<script>
  export default {
        props: {
            input_data: {
                type: Array,
                required: true
            }
        },
       data() {
      return {
        fields: [,{
            key : 'name',
            label: 'Ime'
        }, {
            key : 'email',
            label: 'Imejl'
        },  'Akcije'],
        items: [
        ],

        success: false,
        error : false,
      }
    },
        mounted(){ 
            this.items = this.input_data;
     
        },
  
  methods: {
  	deleteMe: function(id, name){
    	


        this.$bvModal.msgBoxConfirm('Da li zaista želite da obrišete administratora sa imenom "' + name +'"?', {
          title: 'Upozorenje!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Obriši',
          cancelTitle: 'Odustani',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
            if(value){
                //Brisemo administratora
                axios.delete("admin/user/"+id).then(response => {
                    
                    this.success = true;
                    this.items.splice(this.items.findIndex(function(i){
                        return i.id == id;
                        }), 1);
                    
                }).catch(error => {
                    this.error = true;
                });
            }
          })
          .catch(err => {
            // An error occurred
          })

    }}
  }
</script>