<template>
    <div >
        <div class="row justify-content-center">
            <div class="col-md-10">
                <textarea ref="textInput" v-model="localValue" :placeholder="'Maksimalno '  + max_words + ' reči'" type="text" @keyup="check()"  class="form-control " ></textarea>
            </div>
            <div class="col-md-2">
                <span :style="{'color': colorValue}">Broj reči: {{numWords}} / {{max_words}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            max_words: {
                type: Number,
                required: true
            },
             value: {
                type: String,
                required: true
            }
        },
       data(){
            return {
                numWords : 0,
                colorValue : "black"
            }
        },        computed: {
            localValue: {
                get() { return this.value },
                set(localValue) {this.$emit('input', localValue)}
            }
             },        
        methods : {
            check(){
                var words = this.localValue.split(' ').filter(function (el) {
                return el != "";
                });

                this.numWords = words.length;

                if(this.numWords > this.max_words){
                    this.localValue = words.slice(0, this.max_words).join(' ') + ' ';
                    this.colorValue = "red";
                }
                else if(this.numWords == this.max_words){
                     this.colorValue = "red";
                }
                else{
                    this.colorValue = "black";
                }

            }
        } ,
          watch: {
      value(val, oldVal) {
      this.check();
    }
  }
    }
</script>
