<template>
    
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header"> {{this.title + " " + localValue.order}}</div>

                    <div class="card-body">
                        

       <!-- Opis ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="text" class="col-md-4 col-form-label text-md-left">
                    * Objašnjenje
                            </label>
                                <custom-textarea v-model="localValue.description" id="text" :max_words="150"></custom-textarea>

                               
                            </div>
                         
            </div>


         <!--  image ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="image" class="col-md-4 col-form-label text-md-left">
                            * Prilog: fotografija - do 3MB (72 dpi) (JPEG, JPG, TIFF)</label>
                                <file-upload resource="attachment" :resource_id="value.id" field_name="image" :format="'.jpg, .jpeg, .tiff, .tif'" :max_size="3" v-model="localValue.image" id="image" ></file-upload>

                               
                            </div>
                         
            </div>

        
         <!--  pdf ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="document" class="col-md-4 col-form-label text-md-left">
                            Prilog PDF document – do 3MB</label>
                                <file-upload resource="attachment" :resource_id="value.id" field_name="document" :format="'.pdf'" :max_size="3" v-model="localValue.document" id="document" ></file-upload>

                               
                            </div>
                         
            </div>

          <!--  linkovi ---------------------------------------------------------------->
<label class="col-md-4 col-form-label text-md-left">
                            Linkovi:</label>
          <div v-for="(item, index) in localValue.links" :key="index" class="row mb-3">
                           

                            <div class="col-md-10">
                            <b-form-input type="url" v-model="item['link']" placeholder="Link" ></b-form-input>

                            </div>

                            <div class="col-md-2">
                            <button type="button" v-on:click="removeLink(index)" class="btn btn-primary">Izbaci link</button>

                            </div>
                            </div>


                                        <div  class="row mb-3">
<div class="col-md-12">
     
     <button type="button" v-on:click="addLink" class="btn btn-primary">Dodaj link</button>
    </div>
                </div>

                    </div>
                </div>


                
            </div>



        </div>
   
</template>

<script>
    export default {
       props: {
            value : {
                type : Object,
                required: true
            },
            title : {
                type : String,
                required: true
            }
        },
         data(){
            return {
                
            }
    },
        mounted() {
           
            
        }
        ,methods : {

            addLink(){
                this.localValue.links.push({});
            },

            removeLink(index){
                this.$delete(this.localValue.links, index)
            }

        },        computed: {
            localValue: {
                get() { return this.value },
                set(localValue) {this.$emit('input', localValue)}
            }
    }
    }
</script>
