<template>
<div>
    <form @submit.prevent="submit">
        <div class="alert alert-success" v-show="success">{{successMessage}}</div>
        <div class="alert alert-danger" v-show="error"><pre>{{errorMessage}}</pre></div>
             <!-- Naziv ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="name" class="col-md-4 col-form-label text-md-right">* Naziv konkursa</label>
                                <input id="name" type="text" class="form-control" name="name" v-model="data.name" autofocus>

                               
                            </div>
                         
    </div>


     <!-- Opis ---------------------------------------------------------------->
   
    <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="description" class="col-md-4 col-form-label text-md-right">* Opis konkursa</label>
                                <textarea id="description" type="text" class="form-control " name="description" v-model="data.description" autofocus>
                                </textarea>

                               
                            </div>
                            </div>

     <!-- Datum od ---------------------------------------------------------------->

  <div class="row mb-3">
  <div class="col-md-12">
        <label for="from" class="col-md-4 col-form-label text-md-right">* Datum početka konkursa</label>
                             
        <datepicker id="from" name="from" v-model="data.starts" />
  </div>
  </div>     


   <!-- Datum do ---------------------------------------------------------------->    

  <div class="row mb-3">
  <div class="col-md-12">
        <label for="to" class="col-md-4 col-form-label text-md-right">* Datum završetka konkursa</label>
                             
  <datepicker id="to" v-model="data.ends" /></div>

     <!-- Potvrdi ----------------------------------------------------------------> 
  <div class="row mb-3 mt-4">
  <div class="col-md-12">
  <button type="submit" class="btn btn-primary">Potvrdi</button>
  </div>
  </div>
  </div>                  

    </form>
    </div>
</template>

<script>
    import Datepicker from './datepicker.vue';
    export default {
        props: {
            input_data: {
                type: Object,
                required: false
            }
        },
        components: { Datepicker },
        data(){
            return {
                data : {name: "",
                description: "",
                starts: "",
                ends: ""},
                success: false,
                error : false,
                errorMessage: "",
                edit: false,
                successMessage: "",

            }
        },
        mounted(){ 
                if(this.input_data != null){
                     this.data = this.input_data;
                     this.edit = true;   
                }
     
        },
        methods : {
            submit(){
                this.success = false;
                this.error = false;

                let correct = true;
                let emptyFields = "";
                if(this.data.name == ""){
                    correct = false;
                    emptyFields += "Naziv konkursa\n"
                }
                if(this.data.description == ""){
                    correct = false;
                    emptyFields += "Opis konkursa\n"
                }
                if(this.data.starts == ""){
                    correct = false;
                    emptyFields += "Datum početka konkursa\n"
                }
                if(this.data.ends == ""){
                    correct = false;
                    emptyFields += "Datum završetka konkursa\n"
                }

                if(!correct){
                    this.errorMessage = "Sledeća polja su obavezna: \n\n" + emptyFields;
                    this.error = true;
                    return;
                }

                if(this.edit){
                //Azuriramo postojeci konkurs

                 axios.put("/competition/"+this.input_data.id, this.data).then(response => {
                    
                    this.success = true;
                    this.successMessage = "Uspešno ste ažurirali konkurs!";
                    
                }).catch(error => {
                    if(error.response.status == 409){
                        this.errorMessage = "Samo jedan konkurs može biti aktivan u svakom trenutku!";
                    }
                    else{
                        this.errorMessage = "Došlo je do greške prilikom ažuriranja konkursa! Molimo Vas da pokušate ponovo.";
                    }
                    
                    this.error = true;
                });

                }
                else{
                //Kreiramo novi konkurs
                axios.post("/competition", this.data).then(response => {
                    this.data = {};
                    this.success = true;
                    this.successMessage = "Uspešno ste kreirali novi konkurs!";
                    
                }).catch(error => {
                    if(error.response.status == 409){
                        this.errorMessage = "Samo jedan konkurs može biti aktivan u svakom trenutku!";
                    }
                    else{
                        this.errorMessage = "Došlo je do greške prilikom kreiranja novog konkursa! Molimo Vas da pokušate ponovo.";
                    }
                    
                    this.error = true;
                });

                }
            }

            }


    }
</script>

