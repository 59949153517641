<template>
    <b-link v-on:click="deleteSumbission" href="#" class="card-link">Obriši prijavu</b-link>
</template>

<script>

export default ({
    props: {
            target_id: {
                type: Number,
                required: true
            },
             type: {
                type: String,
                required: true
            }
        },
    methods :{ 
        deleteSumbission(){
             this.$bvModal.msgBoxConfirm('Da li ste sigurni da želite da obrišete ovu prijavu?', {
          title: 'Upozorenje!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Potvrdi',
          cancelTitle: 'Odustani',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
                if(value){
                    
                     axios.delete("/"+ this.type + "/"+this.target_id).then(response => {
                    
                   this.boxOne = ''
                    this.$bvModal.msgBoxOk("Uspešno ste obrisali prijavu!")
                    .then(value => {
                     this.boxOne = value;
                     

                         })
                    .catch(err => {
                        // An error occurred
                    });
                    
                }).catch(error => { 
                this.boxOne = ''
                    this.$bvModal.msgBoxOk("Neuspešno brisanje prijave!")
                    .then(value => {
                     this.boxOne = value;
                     

                         })
                    .catch(err => {
                        // An error occurred
                    });

                 });

                }
          })
        }
    }
})
</script>
