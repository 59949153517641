<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">

        <div class="row mb-3">
                           

            <div class="col-md-12">
      <b-alert variant="warning" show>Polja označena zvezdicom (*) obavezno je popuniti. Ukoliko niste ispunili sva polja nećete biti u prilici da pošaljete Vašu prijavu.</b-alert>
  </div></div>

    <!-- Cover photo ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="cover_photo" class="col-md-4 col-form-label text-md-left">
                            * Naslovna fotografija <p>je fotografija kojom se ilustruje kandidatura na komunikacionim kanalima NK ICOM Srbija - do 3MB (72 dpi) (JPEG, JPG, TIFF)</p></label>
                                <file-upload resource="submission" :resource_id="data.id" field_name="cover_photo" :format="'.jpg, .jpeg, .tiff, .tif'" :max_size="3" v-model="data.cover_photo" id="cover_photo" ></file-upload>

                               
                            </div>
                         
            </div>

        
        <!-- Naziv ---------------------------------------------------------------->
       <div class="row ">
                           

                            <div class="col-md-12">
                            <label for="name" class="col-form-label text-md-right">* Ime i prezime</label>
                                <input id="name" type="text" class="form-control" name="name" v-model="data.name" autofocus>

                               
                            </div>
                         
            </div>

       <!-- Organization ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="organization" class="col-md-4 col-form-label text-md-left"> Ustanova / organizacija </label>
                                <input id="organization" type="text" class="form-control" name="organization" v-model="data.organization" autofocus>

                               
                            </div>
                         
            </div>


  


               <!-- Importance ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="importance" class="col-md-4 col-form-label text-md-left">
* Obrazložiti značaj profesionalnog angažmana u stručnom, naučnom, kulturnom i društvenom smislu i/ili inovativnost modela rada. 
Navedite koje ste stručne (profesionalne) iskorake napravlili u nekom (ili više njih) domenu muzejskog rada (istraživanje, dokumentacija, komunikacija, upotreba muzejskog  materijala); koji su naučni doprinosi (ako ih ima – doprinos širenju znanja u matičnoj disciplini); koji su značajni kulturni I društveni doprinosi (unapređenje života zajednica, negovanje kulturne različitosti, uključivanje, ekologija, ravnopravnost…)</label>
                                <custom-textarea v-model="data.importance" id="importance" :max_words="300"></custom-textarea>

                               
                            </div>
                         
            </div>

             <!-- Preporuka ---------------------------------------------------------------->
       <div class="row mb-3">
                           

                            <div class="col-md-12">
                            <label for="recommendation" class="col-md-4 col-form-label text-md-left">
                            Preporuka/e - do 3MB (PDF)</label>
                                <file-upload resource="submission" :resource_id="data.id" field_name="recommendation" :format="'.pdf'" :max_size="3" v-model="data.recommendation" id="recommendation" ></file-upload>

                               
                            </div>
                         
            </div>

                   

    
    <!-- Prilozi ----------------------------------------------------------------> 

     <div class="row mb-3">
                           

            <div class="col-md-12">
      <b-alert show>
          <p><b>PRATEĆA DOKUMENTACIJA</b> sastoji se od obaveznih objašnjenja, fotografija i/ili dokumenata po izboru kandidata koji zajedno, na najbolji način, predstavljaju pregled sveukupnog angažmana muzejskog stručnjaka u različitim oblastima profesionalnog delovanja. </p>
          <p>Molimo da veze ka video zapisima ili ostalim online sadržajima priložite u segmentu „linkovi“.</p>
          <p>Neophodno je ispuniti svih 10 priloga. Ukoliko mislite da je sadržaj nekog priloga veliki za jedno polje, možete ga podeliti na nekoliko segmenata (priloga). Ne zaboravite na neku od Vaših aktivnosti (inovativno istraživanje ili dokumentovanje, konzervatorski postupci, prezentacija (izložba), radionice, digitalni sadržaji, inovativna upotreba materijala…). </p>
          <p>Prilozi su prostor u kojem “kurirate” sopstvenu prijavu, određujete najznačajnije segmente projekta i ilustrujete ih kako bi Komisija imala celovitiji i smisleniji uvid u sveobuhvatnost projekta. </p>
      </b-alert>
  </div></div>


<div class="row mb-3" v-for="(item, index) in data.attachments" :key="index">
                           

            <div class="col-md-12">
    <attachment-form  v-model="data.attachments[index]" title="PRILOG"></attachment-form>
  </div></div>
  

   <div class="row mb-3">
                           

            <div class="col-md-12">
      <b-alert variant="warning" show>
          <p>PROVERITE DA LI STE POPUNILI SVA OBAVEZNA POLJA!!!
NEĆE BITI MOGUĆE POSLATI PRIJAVU UKOLIKO JE NEKO OD OBAVEZNIH POLJA NEPOPUNJENO.</p>
          <p>Nakon čuvanja prijave moći ćete da vršite izmene koliko god želite do momenta njenog slanja. 
Savetujemo, za svaki slučaj, da sve što ste uneli u formular (tekstove, fotografije, linkove) imate sačuvane i u svom personalnom računaru u jednom folderu. </p>
          <p>Nakon što odlučite da pošaljete prijavu i kliknete na dugme “<b>Pošalji</b>” pojaviće Vam se polje u kojem morate potvrditi slanje. Nakon potvrde Vaša prijava je zvanična i vidljiva administratoru sajta. </p>
           </b-alert>
  </div></div>

    <!-- Potvrdi ----------------------------------------------------------------> 
             <div class="alert alert-success" v-show="success">{{successMessage}}</div>
        <div class="alert alert-danger" v-show="error"><pre>{{errorMessage}}</pre></div>

        
  <div class="row mb-3 mt-4">
  <div class="col-md-12">
  <button type="submit" v-on:click="save" class="btn btn-primary">Sačuvaj</button>
    <button type="submit" v-on:click="confirm" class="btn btn-primary">Pošalji prijavu</button>
  </div>
  </div>


</div>
        </div>
    </div>
</template>

<script>
import customTextarea from './customTextarea.vue'
import attachmentForm from './attachmentForm.vue'
    export default {
                props: {
            input_data: {
                type: Object,
                required: false
            }
        },
  components: { customTextarea, attachmentForm },
  data(){
            return {
                data : {name: "",
                organization : "",
                importance : "",
                recommendation: null,
                cover_photo: null,
                attachments : [{description : "", 
                    image : null,
                    links : [ ],
                    order : 1
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 2
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 3
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 4
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 5
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 6
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 7
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 8
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 9
                },
                {description : "", 
                    image : null,
                    links : [ ],
                    order : 10
                }]
                },
                success: false,
                error : false,
                errorMessage: "",
                edit: false,
                successMessage: "",
                preventSuccess: false

            }
        },
        mounted() {
              if(this.input_data != null){

                     this.data = JSON.parse(JSON.stringify(this.input_data, this.replaceNull));
                    
                     this.edit = true;   
                }
        },
        methods: {
            save(){
                this.submit(false);
            },
             submit(confirmation){
               if(this.edit){
                  return this.update(confirmation);
               }
               else{
                 return  this.insert(confirmation);
               }
                
             },
             confirm(){

          this.$bvModal.msgBoxConfirm('Jednom poslata prijava se ne može više menjati. Da li ste sigurni da želite da pošaljete ovu prijavu?', {
          title: 'Upozorenje!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Potvrdi',
          cancelTitle: 'Odustani',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
                if(value){
                    this.submit(true);

                }
          })

             },
            async sendConfirm(id){

                

                 axios.put("/expert/"+id).then(response => {
                    
                    this.success = true;
                    this.successMessage = "Uspešno ste poslali prijavu!";
                    
                }).catch(error => { 
                     var message = "";
                    if(error.response.status == 409){
                        message = 'Niste ispravno popunili sva obavezna polja! Molimo Vas da proverite svoju prijavu.';
                    }
                    else{
                        message = 'Neuspešno slanje prijave! Molimo Vas da pokušate ponovo.';
                    }
                    this.boxOne = ''
                    this.$bvModal.msgBoxOk(message)
                    .then(value => {
                     this.boxOne = value;
                     window.location.href = '/expert/'+id+"/edit";

                         })
                    .catch(err => {
                        // An error occurred
                    })
              

                });

                 

             },
            async insert(confirmation){
                this.error = false;
                var formdata = this.createFormDataMain();
                
                return    axios.post("/expert", formdata,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(response => {

                     //Slanje priloga
                    var id = response.data.id;
                    var url = "/attach/"+id;
                    this.insertAttach(url, confirmation, id);
                    return id;
                 
                 
         
                                
                }).catch(error => {
                     this.errorMessage = "Dodavanje prijave nije uspešno! Molimo Vas da pokušate ponovo.";
                    this.error = true;
                    
                    
                });    

            },
            async update(confirmation){
                
                  this.error = false;
                var formdata = this.createFormDataMain();
                
                return    axios.post("/expert/"+this.input_data.id + "/update", formdata,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(response => {

                    var url = "/attach/"+this.input_data.id + "/update";
                    this.insertAttach(url, confirmation, this.input_data.id);
                       

  }).catch(error => {
                     this.errorMessage = "Ažuriranje prijave nije uspešno! Molimo Vas da pokušate ponovo.";
                    this.error = true;
                    
                    
                });    

            },

            replaceNull(key, value){
                if(key == 'cover_photo' || key == 'recommendation' || key == 'image')
                    return value;
                else
                    return (value === null) ? "" : value;
            },
            async insertAttach(url, confirmation, id){
                 return axios.all([
                        axios.post(url, this.createFormDataAttach(this.data.attachments[0]), {headers: {'Content-Type': 'multipart/form-data' }}), 
                         axios.post(url, this.createFormDataAttach(this.data.attachments[1]), {headers: {'Content-Type': 'multipart/form-data' }}), 
                          axios.post(url, this.createFormDataAttach(this.data.attachments[2]), {headers: {'Content-Type': 'multipart/form-data' }}),
                           axios.post(url, this.createFormDataAttach(this.data.attachments[3]), {headers: {'Content-Type': 'multipart/form-data' }}),
                            axios.post(url, this.createFormDataAttach(this.data.attachments[4]), {headers: {'Content-Type': 'multipart/form-data' }}),
                             axios.post(url, this.createFormDataAttach(this.data.attachments[5]), {headers: {'Content-Type': 'multipart/form-data' }}),
                              axios.post(url, this.createFormDataAttach(this.data.attachments[6]), {headers: {'Content-Type': 'multipart/form-data' }}),
                               axios.post(url, this.createFormDataAttach(this.data.attachments[7]), {headers: {'Content-Type': 'multipart/form-data' }}),
                                axios.post(url, this.createFormDataAttach(this.data.attachments[8]), {headers: {'Content-Type': 'multipart/form-data' }}),
                                 axios.post(url, this.createFormDataAttach(this.data.attachments[9]), {headers: {'Content-Type': 'multipart/form-data' }}),        
                        ])
                        .then(axios.spread((ret1, ret2, ret3, ret4, ret5, ret6, ret7, ret8, ret9,ret10) => {
                        

                        if(ret1.status != 200 ||
                            ret2.status != 200 ||
                            ret3.status != 200 ||
                            ret4.status != 200 ||
                            ret5.status != 200 ||
                            ret6.status != 200 ||
                            ret7.status != 200 ||
                            ret8.status != 200 ||
                            ret9.status != 200 ||
                            ret10.status != 200){
                                this.errorMessage = "Neki od priloga nisu ispravno uneti!";
                                this.error = true;
                            }
                            else{
                                if(!confirmation){
                                                 this.boxOne = ''
                                this.$bvModal.msgBoxOk('Uspešno čuvanje prijave!')
                                .then(value => {
                                this.boxOne = value;
                                window.location.href = '/expert/'+id+"/edit";

                                    })
                                .catch(err => {
                                    // An error occurred
                                })
                                }
                                else{
                                    this.sendConfirm(id);
                                }
                            }
                        }));
            
            },
             createFormDataMain(){
                 
                 var formdata = new FormData();

                 for (var key in this.data) {
                    if (this.data.hasOwnProperty(key)) {
                        if(key == "attach")
                            continue;
                        if(this.data[key] != null)
                            formdata.append(key, this.data[key]);
                    }
                 }

                 return formdata;
             }
             ,
             createFormDataAttach(data){
                 var formdata = new FormData();
                 for (var key in data) {
                    
                    if (data.hasOwnProperty(key)) {
                        if(key == "links"){
                            formdata.append(key, JSON.stringify(data[key]));
                            continue;
                            }
                        if(data[key] != null)
                            formdata.append(key, data[key]);
                    }
                 }

                 return formdata;
             }
        }
    }
</script>
